import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import ReviewRow from './ReviewRow';
import TableHeader from './TableHeader';

function ChangesOnlyView(props) {
    const combinedChanges = useMemo(() => {
        const combined = [];

        Object.keys(props.review.recommendations ?? {}).forEach((category) => {
            combined.push(...props.review.recommendations[category].filter((rec) => {
                return rec.review.length > 0 && (rec.status === 'accepted' || rec.status === 'custom_removal' || rec.status === 'custom_update' || rec.status === 'custom_swap');
            }));
        });

        return combined;
    }, [props.review]);

    return (
        <Stack borderLeft='1px solid rgba(0, 0, 0, 0.5)' borderRight='1px solid rgba(0, 0, 0, 0.5)' borderTop='1px solid rgba(0, 0, 0, 0.5)'>
            <TableHeader />
            {combinedChanges.map((rec, index) => {
                return (
                    <ReviewRow
                        key={rec.id}
                        index={index}
                        item={props.review.items[rec.item.category]?.find((item) => item.id === rec.item.id)}
                        recommendation={rec}
                        draft={props.review}
                        handleUpdate={props.handleUpdate}
                        disabled={props.isReadOnly || rec.review[0].submitted_at !== null}
                    />
                );
            })}
        </Stack>
    );
}

export default ChangesOnlyView;
